import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import '../assets/css/TermsConditions.css';

const PrivacyPolicy = () => {
  return (
    <>
     {/* ========= */}
  <Header/>
  {/* ====== */}

 {/*  Page Title Area Start  */}

 <section className="inner_page_tisection_bred">
  <div className="container-fluid">
    <div className="row align-items-center">
      {/* col */}
      <div className="col-lg-12">
        <div className="page_ti_inner_page">
          <ul>
            <li className="active"><Link to="/">Home</Link></li> -
            <li><Link to="javascript:void(0)"> Privacy Policy</Link></li> 
          </ul>
        </div>
      </div>
      {/* col end */}
    </div>
  </div>
</section>
    {/*Page Title Area End  */}


     <section className="termsconditions_section">
<div className="container">
  {/*  */}
  <div className="termsconditions_title_div">
     <h1>Privacy Policy</h1>
  </div>
  {/*  */}

  {/* col start */}
  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      <div className="termsconditions_txt_div">
          <h5>Introduction</h5>
          <p>Welcome to Healfate Astrology, where we provide insightful astrology readings, consultations, and reports to help individuals navigate their lives with clarity and purpose. We are committed to delivering accurate and personalized astrology services to our valued customers. As part of our commitment to transparency and customer satisfaction, we have outlined our refund and cancellation policy below.</p>

          <h5>Refund Policy</h5>
          <p>At Healfate Astrology, we prioritize the quality and accuracy of our astrology services. Due to the nature of our personalized astrology readings, consultations, and reports, we do not offer refunds once a service is purchased. Our team of experienced astrologers invests time and effort into crafting each reading and report tailored to the individual's unique astrological profile. Therefore, all sales are final, and refunds will not be issued. The amount will be refunded in 7 working days from the day of the concern receipt.</p>

          <h5>Contact Us</h5>
          <p>If you have any questions, concerns, or inquiries regarding our refund and cancellation policy or any other aspect of our astrology services, please don't hesitate to contact us. You can reach our customer support team at contact@healfate.com, and we will be happy to assist you.</p>

          <p><strong>Last Updated: January 1, 2024</strong></p>
      </div>
  </div>
  {/* col end */}
 
</div>
</section>
<Footer/>

    {/* termsconditions section end */}
  </>
  )
}

export default PrivacyPolicy
