import React from 'react';
import '../assets/css/Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
    
  <footer className="footer">
    <div className="footer_overlay">
      <div className="container">
        <div className="row">

          
 {/* col start */}
 <div className="col-lg-2 col-md-6 col-sm-12 col-12">
            <div className="footer_headti">
              <h5>Information</h5>
              <ul>
                <li><a href="/"> <i className="bx bx-droplet" /> Home</a></li>
                <li><a href="/aboutus"> <i className="bx bx-droplet" /> About Us</a></li>
                <li><a href=""> <i className="bx bx-droplet" /> Blogs</a></li>
                <li><a href="/contactus"> <i className="bx bx-droplet" /> Contact Us</a></li>
               
              </ul>
            </div>
          </div>
          {/* col end */}

            
         
          {/* col start */}
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="footer_headti">
              <h5>Water Purifiers</h5>
              <ul>
                <li><a href="index"> <i className="bx bx-droplet" /> Water Purifier</a></li>
                <li><a href=""> <i className="bx bx-droplet" /> RO Water Purifier</a></li>
                <li><a href=""> <i className="bx bx-droplet" /> Water Filter</a></li>
              
                <li><a href=""> <i className="bx bx-droplet" /> UV Water Purifier</a></li>
              </ul>
            </div>
          </div>
          {/* col end */}

 {/* col start */}
 <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="footer_headti">
              <h5>Water Dispensers</h5>
              <ul>
                <li><a href="index"> <i className="bx bx-droplet" /> Hot & Normal. (Omega)</a></li>
                <li><a href=""> <i className="bx bx-droplet" /> Hot, Normal & Cold. (Launching Soon)</a></li>
                <li><a href=""> <i className="bx bx-droplet" /> Hot, Normal, Cold with RO.(Launching Soon)</a></li>
              
                <li><a href=""> <i className="bx bx-droplet" /> Hot, Normal, Cold & Cooling Cabinet.(Launching Soon)</a></li>
              </ul>
            </div>
          </div>
          {/* col end */}

           
 {/* col start */}
 <div className="col-lg-2 col-md-6 col-sm-12 col-12">
            <div className="footer_headti">
              <h5>Buy Now</h5>
              <ul>
                <li><a href="/termsconditions"> <i className="bx bx-droplet" /> Terms & Conditions</a></li>
                <li><a href="/returnrefundpolicy"> <i className="bx bx-droplet" /> Return & Refund Policy</a></li>              
                <li><a href="/privacypolicy"> <i className="bx bx-droplet" /> Privacy Policy</a></li>
                <li><a href="/disclaimer"> <i className="bx bx-droplet" /> Disclaimer</a></li>

              </ul>
            </div>
          </div>
          {/* col end */}
          


        </div>
      </div>
    </div>
    
  </footer>
  {/* ===================================copyright section start================= */}
  <section className="copyright_section">
    <div className="container">
      <div className="row">
        {/* col start  */}
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        <p>Copyright © 2024 Grand Plus Water Purifier. All Right Reserved.</p>
        </div>
        {/* col end */}

         {/* col start  */}
         <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          <p className='copyright_icons'>
            <Link to="/sitemap">Sitemap</Link>
            <Link to="">FAQs</Link>
            <span>
            <Link to="" className='fa fa-facebook'></Link>
            <Link to="" className='fa fa-instagram'></Link>
            <Link to="" className='fa fa-youtube'></Link>
            </span>
          </p>
        </div>
        {/* col end */}
      </div>
    </div>
  </section>
  {/* ===================================copyright section end================= */}


    </>
  )
}

export default Footer
