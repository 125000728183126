


    // choose us slider slider option start
    export const chooseproducts = {
      autoplayHoverPause:true,
      loop:true,
      margin: 5,
      responsiveClass: true,
      nav: false,
      autoplay: true,
      autoplayTimeout: 2000, // 2 seconds
      responsive: {
      0: {
      items: 1,
      nav: false
      },
      600: {
      items: 2,
      nav: false
      },
      1000: {
      items: 4,
      nav: false,
      loop: true
      }
      },
      };
      // choose us slider option end
    

      
    // other buy slider slider option start
    export const otherbuyslider = {
      autoplayHoverPause:true,
      loop:true,
      margin: 5,
      responsiveClass: true,
      nav: false,
      autoplay: true,
      autoplayTimeout: 5000, // 2 seconds
      responsive: {
      0: {
      items: 1,
      nav: false
      },
      600: {
      items: 1,
      nav: false
      },
      1000: {
      items: 3,
      nav: false,
      loop: true
      }
      },
      };
      // other buy slider option end
    