import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './assets/css/Colors.css';
import Home from "./pages/Home";
import Contactus from './pages/Contactus';
import Login from './pages/Login';
import Cart from './pages/Cart';
import Register from './pages/Register';
import Checkout from './pages/Checkout';
import TermsConditions from './pages/TermsConditions';
import ReturnRefundPolicy from './pages/ReturnRefundPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Disclaimer from './pages/Disclaimer';
import Sitemap from './pages/Sitemap';
import AboutUs from './pages/AboutUs';

function App() {
  return (
   <>
   <BrowserRouter>
   <Routes>
    <Route path="/" Component={Home}></Route>
    <Route path="aboutus" Component={AboutUs}></Route>
    <Route path="/contactus" Component={Contactus}></Route>
    <Route path="/login" Component={Login}></Route>
    <Route path="/register" Component={Register}></Route>
    <Route path="/cart" Component={Cart}></Route>
    <Route path="/checkout" Component={Checkout}></Route>
    <Route path="/termsconditions" Component={TermsConditions}></Route>
    <Route path="/returnrefundpolicy" Component={ReturnRefundPolicy}></Route>
    <Route path="/privacypolicy" Component={PrivacyPolicy}></Route>
    <Route path="/disclaimer" Component={Disclaimer}></Route>
    <Route path="/sitemap" Component={Sitemap}></Route>
   </Routes>
   </BrowserRouter>
   </>
  );
}

export default App;
