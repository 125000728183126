import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import '../assets/css/Aboutus.css';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  return (
    <>
        {/* header start */}
    <Header/>
    {/* header start */}


 {/*  Page Title Area Start  */}

 <section className="inner_page_tisection_bred">
  <div className="container-fluid">
    <div className="row align-items-center">
      {/* col */}
      <div className="col-lg-12">
        <div className="page_ti_inner_page">
          <ul>
            <li className="active"><Link to="/">Home</Link></li> -
            <li><Link to="javascript:void(0)"> About Us</Link></li> 
          </ul>
        </div>
      </div>
      {/* col end */}
    </div>
  </div>
</section>
    {/*Page Title Area End  */}

 {/* aboutuspage section start */}
 <section className="aboutuspage_section">
  <div className="container">
    {/*  */}
   <div className="aboutuspage_title_div">
   <h1>About Us</h1>
   </div>
   {/*  */}

    {/* col start */}
    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="aboutuspage_txt_div">
            
           <p>Balaji Traders is a reputable company, our brand is that specializes in providing high-quality water purifiers. We offer a wide range of water purification systems designed to meet the diverse needs of our customers. Our aim is to ensure that everyone has access to clean and safe drinking water.</p>
           <p>At Balaji Traders, we understand the importance of clean water for maintaining good health. Our water purifiers are equipped with advanced filtration technologies to remove impurities, contaminants, and harmful substances from the water. Whether you need a water purifier for your home, office, or any other establishment, we have the right solution for you.</p>
           <p>At Balaji Traders, we understand the importance of clean water in maintaining good health. We strive to address the growing concerns about water contamination and provide effective solutions to purify water from various sources. Our range of water purifiers incorporates advanced technologies to remove impurities, contaminants, and harmful substances, while retaining essential minerals for a healthy drinking water experience.</p>
           <p>We take pride in offering a diverse range of water purifiers to suit different needs and budgets. Our products are designed to be efficient, reliable, and easy to use, providing convenience and peace of mind to our customers. Whether it's for residential, commercial, or industrial use, we have solutions that meet the highest quality standards.</p>
           <p>In addition to our exceptional products, we are dedicated to delivering excellent customer service. Our knowledgeable and friendly team is always ready to assist customers in choosing the right water purifier and providing any necessary after-sales support. We prioritize customer satisfaction and strive to build long-lasting relationships based on trust and reliability.</p>
           <p>Balaji Traders is committed to innovation and continuous improvement. We stay up to date with the latest advancements in water purification technology and regularly update our product offerings to ensure that our customers have access to the most effective and reliable solutions.</p>
        </div>
    </div>
    {/* col end */}
   
  </div>
</section>

{/* end about us */}

  {/* header start */}
  <Footer/>
    {/* header start */}

    </>
  )
}

export default AboutUs
