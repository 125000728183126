import React from 'react';
import '../assets/css/Header.css';
import { Link } from 'react-router-dom';
import Sidenavbar from './Sidenavbar';

const Header = () => {
  return (
    <>
    {/* main div header wrapper start */}
    <div className="header_main_wraper_div">
   
    {/* top section start */}
    <section className="top_bar_section">
        <div className="container">
            <div className="row">

                 {/* col start */}
                 <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="topbar_left_div_txt">
                        <Link to="">Customer Service</Link>
                        <Link to="">Where To Buy</Link>
                        <Link to="/aboutus">About Us</Link>
                        <Link to="">Become a KSA</Link>
                    </div>
                 </div>
                 {/* col end */}

                  {/* col start */}
                 <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                    <div className="topbar_center_div_txt">
                        <Link to=""> <i class='bx bx-location-plus bx-tada' ></i> Enter your location pin code</Link>
                    </div>
                 </div>
                 {/* col end */}

                 {/* col start */}
                 <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                 <div className="topbar_right_div_txt">
                 <Link to="tel:+919810392397"> <i class='bx bx-phone bx-tada' ></i>  +91-9810392397</Link>
                 <Link to="mailto:helpdesk@aquafreshwaterpurifiers.in"> <i className='bx bx-envelope bx-tada' ></i> helpdesk@aquafreshwaterpurifiers.in</Link>
                 </div>
                 </div>
                 {/* col end */}

            </div>
        </div>
    </section>
    {/* top section end */}

     {/* navbar section start */}
     <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src="../logo/logo2.png" className='img-fluid' alt="" />
          </Link>
          {/* phone view icons s */}
          <div className="phone_view_icons_div">
            <span className='phone_icons_nav'>
              
              <Link to="/login" className='bx bxs-user-circle'></Link>
              <Link to="/cart" className='bx bx-cart  position-relative'><span className="badge position-absolute top-1 start-100 translate-middle">4</span></Link>
            </span>
            <Link to="#sidenavbar" className='offcanvas_btn bx bx-menu-alt-right' data-bs-toggle="offcanvas" aria-controls="offcanvasScrolling"></Link>
          </div>
          {/* end */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto">
              <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
              <li className="nav-item"><Link className="nav-link" to=""> Water Purifiers</Link></li>
              <li className="nav-item"><Link className="nav-link" to=""> Vacuum Cleaners</Link></li>
              <li className="nav-item"><Link className="nav-link" to=""> Kitchen Appliances</Link></li>
              <li className="nav-item"><Link className="nav-link" to=""> Water Dispensers</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/contactus"> Contact Us</Link></li>
            </ul>
            {/* navbar search div */}
            <form className="d-flex navbar_search_div" role="search">
            <div className="input-group">
  <input type="text" className="form-control" placeholder="Type to search" aria-label="Username" aria-describedby="basic-addon1"/>  
  <Link to="" className="input-group-text bx bx-search-alt" id="basic-addon1"></Link>
  </div>
      </form>
        {/* navbar search div */}
        
        <ul className="navbar_user_cart">
              <li className="nav-item"><Link className="nav-link" to="/login"> <span className='bx bx-user-circle'></span> Sign In</Link></li>
              <li className="nav-item"><Link className="nav-link position-relative" to="/cart"> <span className='bx bx-cart'></span> Cart <span className="badge position-absolute top-0 start-10 translate-middle text-white">2</span></Link></li>
            </ul>
          </div>
        </div>
      </nav>
      {/* end navr section */}

    </div>
     {/* main div header wrapper end */}
     
     {/* sidenavbar start */}
<Sidenavbar/>
{/* sidenavbar end */}
    </>
  )
}

export default Header
