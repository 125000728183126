import React from 'react'
const Sidenavbar = () => {
  return (
    <>
    {/* side bar nav main div start */}
    <div className="sidebar_main_div">
  <div class="offcanvas offcanvas-start d-block d-lg-none" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="sidenavbar" aria-labelledby="sidenavbarLabel">
    {/* start header */}
 <div className="offcanvas-header"> 
  <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
    <a href="/login" data-bs-toggle="modal" data-bs-target="#login">
    <img src="../images/userimg.png" alt /> SIGN IN/SIGN UP <i className='bx bx-log-in-circle'></i></a>
    </h5>
  <button type="button" className="fa fa-times" data-bs-dismiss="offcanvas" aria-label="Close" />
</div>
{/* end haeder */}

{/* offcanvas body start */}
    <div className="offcanvas-body">
     {/* sidebar navlink div start */}
     <div className="sidenav_navlink_div">
        <ul>
            <li><a href="/"> <i className='bx bx-home'></i> Home</a></li>
            <li><a href="/aboutus"><i className='bx bx-book-reader'></i> About Us</a></li>

             {/* Water Purifiers li */}
             <div className="categories_div_main">
     <li><a href="javascript:void(0);"><i className='bx bx-category-alt'></i> 
     Water Purifiers </a> <span data-bs-toggle="collapse" data-bs-target="#categories-menu01" className="bx bx-chevron-down" /></li>
  <div className="collapse" id="categories-menu01">
    <div className="card card-body categories_card_body">
      <ul className="categories_ul_list">
         <li><a href=""><i class='bx bx-droplet'></i> Water Purifier</a></li>
         <li><a href=""><i class='bx bx-droplet'></i> RO Water Purifier</a></li>
         <li><a href=""><i class='bx bx-droplet'></i> Water Filter</a></li>
         <li><a href=""><i class='bx bx-droplet'></i> UV Water Purifier</a></li>
         <li><a href=""><i class='bx bx-droplet'></i> Gravity Water Purifier</a></li>
         <li><a href=""><i class='bx bx-droplet'></i> Commercial Water Purifier</a></li>
      </ul>
    </div>
  </div>
  </div>
  {/*Water Purifiers drop li end */}

   {/* Vacuum Cleaners li */}
   <div className="categories_div_main">
     <li><a href="javascript:void(0);"><i className='bx bx-category-alt'></i> 
     Vacuum Cleaners </a> <span data-bs-toggle="collapse" data-bs-target="#categories-menu02" className="bx bx-chevron-down" /></li>
  <div className="collapse" id="categories-menu02">
    <div className="card card-body categories_card_body">
      <ul className="categories_ul_list">
         <li><a href=""><i class='bx bx-droplet'></i> Wiz Handheld Vacuum Cleaner</a></li>
         <li><a href=""><i class='bx bx-droplet'></i> Wet & Dry Vacuum Cleaner. (Launching Soon)</a></li>
      </ul>
    </div>
  </div>
  </div>
  {/*Vacuum Cleaners drop li end */}


   {/* Kitchen Appliances li */}
   <div className="categories_div_main">
     <li><a href="javascript:void(0);"><i className='bx bx-category-alt'></i> 
     Kitchen Appliances </a> <span data-bs-toggle="collapse" data-bs-target="#categories-menu03" className="bx bx-chevron-down" /></li>
  <div className="collapse" id="categories-menu03">
    <div className="card card-body categories_card_body">
      <ul className="categories_ul_list">
         <li><a href=""><i class='bx bx-droplet'></i> Mixer Grinder</a></li>
         <li><a href=""><i class='bx bx-droplet'></i> Egg Boiler</a></li>
      </ul>
    </div>
  </div>
  </div>
  {/*Kitchen Appliances drop li end */}

   {/* Water Dispensers li */}
   <div className="categories_div_main">
     <li><a href="javascript:void(0);"> <i className='bx bx-category-alt'></i> 
      Water Dispensers </a> <span data-bs-toggle="collapse" data-bs-target="#categories-menu04" className="bx bx-chevron-down" /></li>
  <div className="collapse" id="categories-menu04">
    <div className="card card-body categories_card_body">
      <ul className="categories_ul_list">
         <li><a href=""><i class='bx bx-droplet'></i> Hot & Normal (Omega)</a></li>
         <li><a href=""><i class='bx bx-droplet'></i> Hot, Normal & Cold (Launching Soon)</a></li>
         <li><a href=""><i class='bx bx-droplet'></i> Hot, Normal, Cold & Cooling Cabinet (Launching Soon)</a></li>
         <li><a href=""><i class='bx bx-droplet'></i> Hot, Normal, Cold with RO.(Launching Soon)</a></li>
      </ul>
    </div>
  </div>
  </div>
  {/*Water Dispensers drop li end */}

            <li><a href="/sitemap"><i class='bx bx-sitemap' ></i> Sitemap</a></li>
            <li><a href=""><i class='bx bx-message-alt-dots'></i> FAQ</a></li>
            <li><a href="/contactus"><i className='bx bxs-contact'></i> Contact Us</a></li>
        </ul>
     </div>
     {/* sidebar navlink div end*/}

{/* side bar profile link start  */}
<div className="sidebar_profile_div_main">
    <ul>
        <li><a href=""><i className='bx bxs-user-detail'></i> My Profile</a></li>
        <li><a href=""><i className='bx bxs-shopping-bags'></i> Orders</a></li>
    </ul>
</div>
{/* side bar profile link end */}

{/* side bar terms link start  */}
<div className="sidebar_terms_div_main">
    <ul>
        <li><a href="/termsconditions"><i className='bx bx-file'></i> Terms & Conditions</a></li>       
         <li><a href="/returnrefundpolicy"><i className='bx bx-money'></i> Refund & Cancellation</a></li>
        <li><a href="/privacypolicy"><i className='bx bx-check-shield'></i> Privacy Policy</a></li>
        <li><a href="/disclaimer"><i className='bx bx-check-shield'></i> Disclaimer</a></li>
    </ul>
</div>
{/* side bar terms link end */}

    </div>
    {/* offcanvas body end */}

  </div>
  </div>
    {/* side bar nav main div end */}

    </>
  )
}

export default Sidenavbar
