import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

const Register = () => {
  return (
    <>
     {/* Header start*/}
     <Header/>
    {/* Header end */}

    <section className="login_page_section">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-6 col-12">
        <div className="login_page_div_main">
          <h3>Create Account</h3>
          <form action>
            <label>Name</label>
            <div className="input-group mb-3">
            <span className="input-group-text fa fa-user" id="basic-addon1" />
            <input type="text" className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
            </div>
            <label>Email ID</label>
            <div className="input-group mb-3"><span className="input-group-text fa fa-envelope" id="basic-addon1" /><input type="text" className="form-control" aria-label="Username" aria-describedby="basic-addon1" /></div>
            <label>Mobile No.</label>
            <div className="input-group mb-3"><span className="input-group-text fa fa-phone" id="basic-addon1" /><input type="text" className="form-control" aria-label="Username" aria-describedby="basic-addon1" /></div>
            <label>Password</label>
            <div className="input-group mb-3"><span className="input-group-text fa fa-lock" id="basic-addon1" /><input type="password" className="form-control" aria-label="Username" aria-describedby="basic-addon1" /></div>
            <button type="submit" className="btn btn">Register Now</button>
            <div className="login_div_flx">
              <p>Already have an account -  <a href="/login">Login Here</a></p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

 {/* Header start*/}
     <Footer/>
    {/* Header end */}
    </>
  )
}

export default Register
