import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../assets/css/Home.css';
import { Link } from 'react-router-dom';
import { chooseproducts, otherbuyslider } from '../data/Owlcarouseloptions';
import OwlCarousel from 'react-owl-carousel';

const Home = () => {
  return (
    <>
    {/* Header start*/}
     <Header/>
    {/* Header end */}
    
    {/* hero slider start */}
  <section className="hero_slider_section">
  <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="../banner/1.png" className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item">
    <img src="../banner/1.png" className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item">
    <img src="../banner/1.png" className="d-block w-100" alt="..." />
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>

  </section>
    {/* hero slider end */}

    {/* Best Selling Purifiers start section */}
    <section className="best_selling_section">
      <div className="container">
        {/* title start */}
     <div className="best_selling_title">
      <h1>Best Selling Purifiers </h1>
     </div>
        {/* title end */}
        {/* row start */}
        <div className="row">
          {/* col start */}
          <div className="col-lg-3 col-md-4 col-sm-6 col-6">
            <div className="best_selling_product_main_div">
              <Link to="">
              <div className="best_selling_img_box">
                <img src="../products/1.jpg" alt="" className='img-fluid'/>
              </div>
              <div class="selling_products_name_div">
                 <h2>Water Purifiers</h2>
                <p><b>₹ 9,733.00 /-</b> <span>  ₹ 11,450.00 /-</span></p>
                <h6> Best UV Water Purifier</h6>
                </div>
                </Link> 

                <div className="selling_cart_btn_div">
                  <Link to=""> <i class='bx bx-cart-add'></i> Add to cart</Link>
                  <Link to=""> <i class='bx bx-heart'></i> </Link>
                </div>
            </div>
          </div>
          {/* col end */}

           {/* col start */}
           <div className="col-lg-3 col-md-4 col-sm-6 col-6">
            <div className="best_selling_product_main_div">
              <Link to="">
              <div className="best_selling_img_box">
                <img src="../products/2.jpg" alt="" className='img-fluid'/>
              </div>
              <div class="selling_products_name_div">
                 <h2>RO Water Purifier</h2>
                <p><b>₹ 9,733.00 /-</b> <span> ₹ ₹11,450.00 /-</span></p>
                <h6> Best UV Water Purifier</h6>
                </div>
                </Link> 

                <div className="selling_cart_btn_div">
                  <Link to=""> <i class='bx bx-cart-add'></i> Add to cart</Link>
                  <Link to=""> <i class='bx bx-heart'></i> </Link>
                </div>
            </div>
          </div>
          {/* col end */}

           {/* col start */}
           <div className="col-lg-3 col-md-4 col-sm-6 col-6">
            <div className="best_selling_product_main_div">
              <Link to="">
              <div className="best_selling_img_box">
                <img src="../products/3.jpg" alt="" className='img-fluid'/>
              </div>
              <div class="selling_products_name_div">
                 <h2>Gravity Water Purifier</h2>
                <p><b>₹ 9,733.00 /-</b> <span> ₹ ₹11,450.00 /-</span></p>
                <h6> Best UV Water Purifier</h6>
                </div>
                </Link> 

                <div className="selling_cart_btn_div">
                  <Link to=""> <i class='bx bx-cart-add'></i> Add to cart</Link>
                  <Link to=""> <i class='bx bx-heart'></i> </Link>
                </div>
            </div>
          </div>
          {/* col end */}

           {/* col start */}
           <div className="col-lg-3 col-md-4 col-sm-6 col-6">
            <div className="best_selling_product_main_div">
              <Link to="">
              <div className="best_selling_img_box">
                <img src="../products/4.jpg" alt="" className='img-fluid'/>
              </div>
              <div class="selling_products_name_div">
                 <h2>Commercial Water Purifier</h2>
                <p><b>₹ 9,733.00 /-</b> <span> ₹ ₹11,450.00 /-</span></p>
                <h6> Best UV Water Purifier</h6>
                </div>
                </Link> 

                <div className="selling_cart_btn_div">
                  <Link to=""> <i class='bx bx-cart-add'></i> Add to cart</Link>
                  <Link to=""> <i class='bx bx-heart'></i> </Link>
                </div>
            </div>
          </div>
          {/* col end */}
        </div>
        {/* row end */}
      </div>
    </section>
    {/* Best Selling Purifiers end section */}

    {/* ===========Offers section start============== */}
    <section className="offer_section">
      <div className="container">
         {/* title start */}
     <div className="offer_title">
      <h2>Offers </h2>
     </div>
        {/* title end */}
        {/* row start */}
        <div className="row">
          {/* col start  */}
          <div className="col-lg-4 col-md-4 col-sm-6 col-12">
            <div className="offer_main_div">
              {/* flex main div offer start */}
              <div className="offer_flex_main_div">
                <div className="img_offer_div">
                  <img src="../products/4.jpg" alt="" className='img-fluid'/>
                </div>
                <div className="offer_txt_div">
                  <h3>Use HEALTHYCOPPER code and get 10% OFF </h3>
                  <p>Limited time offer </p>
                  <Link to="">Explore <span className='fa fa-arrow-circle-o-right'></span></Link>
                </div>
              </div>
              {/* flex main div offer end */}
              {/*  */}
            </div>
          </div>
          {/* col end */}

           {/* col start  */}
           <div className="col-lg-4 col-md-4 col-sm-6 col-12">
            <div className="offer_main_div">
              {/* flex main div offer start */}
              <div className="offer_flex_main_div">
                <div className="img_offer_div">
                  <img src="../products/3.jpg" alt="" className='img-fluid'/>
                </div>
                <div className="offer_txt_div">
                  <h3>Use HEALTHYCOPPER code and get 10% OFF </h3>
                  <p>Limited time offer </p>
                  <Link to="">Explore <span className='fa fa-arrow-circle-o-right'></span></Link>
                </div>
              </div>
              {/* flex main div offer end */}
              {/*  */}
            </div>
          </div>
          {/* col end */}


           {/* col start  */}
           <div className="col-lg-4 col-md-4 col-sm-6 col-12">
            <div className="offer_main_div">
              {/* flex main div offer start */}
              <div className="offer_flex_main_div">
                <div className="img_offer_div">
                  <img src="../products/2.jpg" alt="" className='img-fluid'/>
                </div>
                <div className="offer_txt_div">
                  <h3>Use HEALTHYCOPPER code and get 10% OFF </h3>
                  <p>Limited time offer </p>
                  <Link to="">Explore <span className='fa fa-arrow-circle-o-right'></span></Link>
                </div>
              </div>
              {/* flex main div offer end */}
              {/*  */}
            </div>
          </div>
          {/* col end */}

        </div>
        {/* row end */}
      </div>
    </section>
    {/* ===========Offers section end============== */}

    {/*====== choose products section start======= */}
    <section className="choose_products_section">
  <div className="container">
    {/* chooseproducts title */}
 <div className="choose_products_title">
  <h2>choose your product</h2>
 </div>
    {/* end  */}
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      <div className="choose_products_slider_main_div">
      <OwlCarousel className="owl-theme" {...chooseproducts}>
{/* item div start */}
<div className="item">
<div className="choose_product_main_div">
              <Link to="">
              <div className="choose_img_box">
                <img src="../products/4.jpg" alt="" className='img-fluid'/>
              </div>
              <div class="choose_products_name_div">
                 <h2>Gravity Water Purifier</h2>
                <p><b>₹ 9,733.00 /-</b> <span> ₹ ₹11,450.00 /-</span></p>
                <h6> Best UV Water Purifier</h6>
                </div>
                </Link> 

                <div className="choose_cart_btn_div">
                  <Link to=""> <i class='bx bx-cart-add'></i> Add to cart</Link>
                  <Link to=""> <i class='bx bx-heart'></i> </Link>
                </div>
            </div>
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div className="choose_product_main_div">
              <Link to="">
              <div className="choose_img_box">
                <img src="../products/2.jpg" alt="" className='img-fluid'/>
              </div>
              <div class="choose_products_name_div">
                 <h2>Gravity Water Purifier</h2>
                <p><b>₹ 9,733.00 /-</b> <span> ₹ ₹11,450.00 /-</span></p>
                <h6> Best UV Water Purifier</h6>
                </div>
                </Link> 

                <div className="choose_cart_btn_div">
                  <Link to=""> <i class='bx bx-cart-add'></i> Add to cart</Link>
                  <Link to=""> <i class='bx bx-heart'></i> </Link>
                </div>
            </div>
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div className="choose_product_main_div">
              <Link to="">
              <div className="choose_img_box">
                <img src="../products/3.jpg" alt="" className='img-fluid'/>
              </div>
              <div class="choose_products_name_div">
                 <h2>Gravity Water Purifier</h2>
                <p><b>₹ 9,733.00 /-</b> <span> ₹ ₹11,450.00 /-</span></p>
                <h6> Best UV Water Purifier</h6>
                </div>
                </Link> 

                <div className="choose_cart_btn_div">
                  <Link to=""> <i class='bx bx-cart-add'></i> Add to cart</Link>
                  <Link to=""> <i class='bx bx-heart'></i> </Link>
                </div>
            </div>
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div className="choose_product_main_div">
              <Link to="">
              <div className="choose_img_box">
                <img src="../products/1.jpg" alt="" className='img-fluid'/>
              </div>
              <div class="choose_products_name_div">
                 <h2>Gravity Water Purifier</h2>
                <p><b>₹ 9,733.00 /-</b> <span> ₹ ₹11,450.00 /-</span></p>
                <h6> Best UV Water Purifier</h6>
                </div>
                </Link> 

                <div className="choose_cart_btn_div">
                  <Link to=""> <i class='bx bx-cart-add'></i> Add to cart</Link>
                  <Link to=""> <i class='bx bx-heart'></i> </Link>
                </div>
            </div>
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div className="choose_product_main_div">
              <Link to="">
              <div className="choose_img_box">
                <img src="../products/4.jpg" alt="" className='img-fluid'/>
              </div>
              <div class="choose_products_name_div">
                 <h2>Gravity Water Purifier</h2>
                <p><b>₹ 9,733.00 /-</b> <span> ₹ ₹11,450.00 /-</span></p>
                <h6> Best UV Water Purifier</h6>
                </div>
                </Link> 

                <div className="choose_cart_btn_div">
                  <Link to=""> <i class='bx bx-cart-add'></i> Add to cart</Link>
                  <Link to=""> <i class='bx bx-heart'></i> </Link>
                </div>
            </div>
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div className="choose_product_main_div">
              <Link to="">
              <div className="choose_img_box">
                <img src="../products/3.jpg" alt="" className='img-fluid'/>
              </div>
              <div class="choose_products_name_div">
                 <h2>Gravity Water Purifier</h2>
                <p><b>₹ 9,733.00 /-</b> <span> ₹ ₹11,450.00 /-</span></p>
                <h6> Best UV Water Purifier</h6>
                </div>
                </Link> 

                <div className="choose_cart_btn_div">
                  <Link to=""> <i class='bx bx-cart-add'></i> Add to cart</Link>
                  <Link to=""> <i class='bx bx-heart'></i> </Link>
                </div>
            </div>
</div>
{/* item div end */}

</OwlCarousel>
      </div>
      </div>
      {/* col end */}
    </div>
    {/* row end */}
  </div>
</section>
    {/*====== choose products section end===== */}

    
    {/* ===========Offers section start============== */}
    <section className="other_way_buy_section">
      <div className="container">
         {/* title start */}
     <div className="other_way_buy_title">
      <h2>Other Way to buy </h2>
     </div>
        {/* title end */}

{/* row start */}
<div className="row">
      {/* col start */}
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      <div className="choose_products_slider_main_div">
      <OwlCarousel className="owl-theme" {...otherbuyslider}>
{/* item div start */}
<div className="item">
<div className="other_buy_main_div">
            <Link to="">
            <div className="other_way_img_box grand_logo">
              <img src="../images/grands.png" alt="" className='img-fluid'/>
            </div>
            <h4>Grand Plus Online Store</h4>
            </Link>
           </div>
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div className="other_buy_main_div">
           <Link to="">
           <div className="other_way_img_box">
              <img src="../images/amazon.png" alt="" className='img-fluid'/>
            </div>
            <h4>Amazon Store</h4>
           </Link>
           </div>
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div className="other_buy_main_div">
           <Link to="">
           <div className="other_way_img_box">
              <img src="../images/flipkart.png" alt="" className='img-fluid'/>
            </div>
            <h4>Flipkart Store</h4>
           </Link>
           </div>
</div>
{/* item div end */}


</OwlCarousel>
      </div>
      </div>
      {/* col end */}
    </div>
    {/* row end */}

       
      </div>
    </section>
    {/* ===========Offers section end============== */}

       {/* Footer start*/}
       <Footer/>
    {/* Footer end */}
    </>
  )
}

export default Home
