import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import '../assets/css/sitemap.css';

const Sitemap = () => {
  return (
    <>
    {/* header start */}
    <Header/>
    {/* header start */}

    
 {/*  Page Title Area Start  */}

 <section className="inner_page_tisection_bred">
  <div className="container-fluid">
    <div className="row align-items-center">
      {/* col */}
      <div className="col-lg-12">
        <div className="page_ti_inner_page">
          <ul>
            <li className="active"><Link to="/">Home</Link></li> -
            <li><Link to="javascript:void(0)"> Sitemap</Link></li> 
          </ul>
        </div>
      </div>
      {/* col end */}
    </div>
  </div>
</section>
    {/*Page Title Area End  */}

{/* sitemap section start */}
<section className="sitemap_section">
<div className="container">
    <div className="row">
        {/* col start */}
        <div className="col-lg-3 col-md-4 col-sm-12 col-12">
            <div className="main_div_sitemap_links">
            <h2>Water Purifiers</h2>
            <ul>
            <li><Link to=""><i class='bx bx-droplet'></i> Water Purifier</Link></li>
         <li><Link to=""><i class='bx bx-droplet'></i> RO Water Purifier</Link></li>
         <li><Link to=""><i class='bx bx-droplet'></i> Water Filter</Link></li>
         <li><Link to=""><i class='bx bx-droplet'></i> UV Water Purifier</Link></li>
         <li><Link to=""><i class='bx bx-droplet'></i> Gravity Water Purifier</Link></li>
         <li><Link to=""><i class='bx bx-droplet'></i> Commercial Water Purifier</Link></li>
                </ul>
            </div>
        </div>
        {/* col end */}

         {/* col start */}
         <div className="col-lg-3 col-md-4 col-sm-12 col-12">
            <div className="main_div_sitemap_links">
            <h2>Vacuum Cleaners</h2>
            <ul>
            <li><Link to=""><i class='bx bx-droplet'></i> Wiz Handheld Vacuum Cleaner</Link></li>
            <li><Link to=""><i class='bx bx-droplet'></i> Wet & Dry Vacuum Cleaner. (Launching Soon)</Link></li>
                </ul>
            </div>
        </div>
        {/* col end */}

        
         {/* col start */}
         <div className="col-lg-3 col-md-4 col-sm-12 col-12">
            <div className="main_div_sitemap_links">
            <h2>Kitchen Appliances</h2>
            <ul>
            <li><Link to=""><i class='bx bx-droplet'></i> Mixer Grinder</Link></li>
            <li><Link to=""><i class='bx bx-droplet'></i> Egg Boiler</Link></li>
                </ul>
            </div>
        </div>
        {/* col end */}

          
         {/* col start */}
         <div className="col-lg-3 col-md-4 col-sm-12 col-12">
            <div className="main_div_sitemap_links">
            <h2>Water Dispensers</h2>
                <ul>
                <li><Link to=""><i class='bx bx-droplet'></i> Hot & Normal (Omega)</Link></li>
         <li><Link to=""><i class='bx bx-droplet'></i> Hot, Normal & Cold (Launching Soon)</Link></li>
         <li><Link to=""><i class='bx bx-droplet'></i> Hot, Normal, Cold & Cooling Cabinet (Launching Soon)</Link></li>
         <li><Link to=""><i class='bx bx-droplet'></i> Hot, Normal, Cold with RO.(Launching Soon)</Link></li>
                </ul>
            </div>
        </div>
        {/* col end */}

           {/* col start */}
           <div className="col-lg-3 col-md-4 col-sm-12 col-12">
            <div className="main_div_sitemap_links">
            <h2>Information</h2>
                <ul>
                <li><Link to="/termsconditions"> <i className="bx bx-droplet" /> Terms & Conditions</Link></li>
                <li><Link to="/returnrefundpolicy"> <i className="bx bx-droplet" /> Return & Refund Policy</Link></li>              
                <li><Link to="/privacypolicy"> <i className="bx bx-droplet" /> Privacy Policy</Link></li>
                <li><Link to="/disclaimer"> <i className="bx bx-droplet" /> Disclaimer</Link></li>
                <li><Link to="/sitemap"> <i className="bx bx-droplet" /> sitemap</Link></li>
                <li><Link to="/aboutus"> <i className="bx bx-droplet" /> About us</Link></li>
                <li><Link to="/contactus"> <i className="bx bx-droplet" /> Contact us</Link></li>
                </ul>
            </div>
        </div>
        {/* col end */}

        
    </div>
</div>
</section>
{/* sitemap section end */}


   
    <Footer/>
    </>
  )
}

export default Sitemap
